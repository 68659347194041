import styled from "styled-components"
import { themeGet } from "@styled-system/theme-get"

const ConsultationWrapper = styled.div`
  position: relative;
  background: ${props =>
    props.colorReverse
      ? "linear-gradient(180deg, #000 50%, #fff 50%)"
      : props.greyBg
      ? "linear-gradient(180deg, #F9F9F9 50%, #000 50%)"
      : "linear-gradient(180deg, #ffffff 50%, #000 50%)"};
  margin-top: ${props => (props.extraPadding ? "60px" : "0px")};
  padding: 0px 30px 0px 30px;

  @media (max-width: 1220px) {
    margin-top: ${props => (props.extraPadding ? "50px" : "0px")};
    padding: 0px 30px 0px 30px;
  }
  @media (max-width: 990px) {
    margin-top: ${props => (props.extraPadding ? "40px" : "0px")};
  }
  @media (max-width: 480px) {
    margin-top: ${props => (props.extraPadding ? "25px" : "0px")};
    padding: 0px 30px 0px 30px;
  }

  .container {
    background-color: ${themeGet("colors.mainBlue")};
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 100px 80px;
    @media (max-width: 990px) {
      flex-wrap: wrap;
      justify-content: center;
    }
    @media only screen and (max-width: 480px) {
      padding: 30px 30px 30px;
    }
  }
`

export const ContactFormWrapper = styled.div`
  display: flex;
  flex-direction: column;
  /* align-items: flex-end; */
  width: 475px;
  max-width: 100%;
  margin-left: 0px;
  margin-right: 0px;
  margin-top: -13px;
  @media (max-width: 990px) {
    width: 100%;
  }
  @media (max-width: 575px) {
    width: 100%;
  }
  @media (max-width: 575px) {
    flex-direction: column;
    align-items: center;
    margin-bottom: 25px;
    button {
      width: 100%;
    }
  }

  .reusecore__button {
    background-color: ${themeGet("colors.white")};
    color: ${themeGet("colors.mainBlue")};
    font-size: 20px;
    border-radius: 0px;
    padding: 22px 21px;
    text-transform: capitalize;
    margin-top: 35px;
    margin-bottom: -10px;
    width: 100%;
    &:hover {
      background-color: ${themeGet("colors.whiteHover")};
    }
    @media only screen and (max-width: 480px) {
      font-size: 18px;
    }
  }
`

export default ConsultationWrapper
