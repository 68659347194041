/* eslint-disable */
import * as yup from "yup"
import React from "react"
import { TextField, withStyles } from "@material-ui/core"
import { navigate } from "gatsby"
import { withFormik } from "formik"

import ButtonSubmit from "../../components/Button"
import validationsForm from "../../validations/validationSchemaConsult"

const styles = {
  root: {
    "& .MuiFormLabel-root": {
      color: "white",
      fontSize: "18px",
      fontFamily: "Open Sans",
    },
    padding: "12px 0",
    "& .MuiInputBase-root": {
      color: "white",
    },
    "& .MuiFormHelperText-root.Mui-error": {
      color: "#C3423F",
    },
    "& .MuiInput-underline.Mui-error:after": {
      borderBottomColor: "#C3423F",
    },
    "& .MuiInput-underline:after": {
      borderBottom: "1px solid white",
    },
    "& .MuiInput-underline:before": {
      borderBottom: "1px solid white",
    },
    "& .MuiInput-underline:hover:not(.Mui-disabled):before": {
      borderBottom: "1px solid white",
    },
    "& label + .MuiInput-formControl": {
      marginTop: "30px",
    },
    "& .MuiInputBase-inputMarginDense": {
      paddingTop: "5px",
    },
    "& .WithFormik(form)-root-1 .MuiInputBase-inputMarginDense": {
      paddingTop: "9px",
    },
    "& .MuiFormControl-marginDense": {
      marginTop: "5px",
      /* margin-bottom: 3px; */
    },
  },
}

const form = props => {
  const {
    classes,
    values,
    touched,
    errors,
    handleChange,
    handleBlur,
    handleSubmit,
    pathname,
  } = props

  return (
    <form
      onSubmit={handleSubmit}
      name="consultation"
      method="post"
      data-netlify="true"
      data-netlify-honeypot="bot-field"
    >
      <input type="hidden" name="form-name" value="consultation" />
      <TextField
        id="name"
        label="NAME"
        name="name"
        value={values.name}
        onChange={handleChange}
        onBlur={handleBlur}
        helperText={touched.name ? errors.name : ""}
        error={touched.name && Boolean(errors.name)}
        margin="dense"
        className={classes.root}
        fullWidth
      />
      <TextField
        id="email"
        label="EMAIL"
        name="email"
        type="email"
        value={values.email}
        onChange={handleChange}
        onBlur={handleBlur}
        helperText={touched.email ? errors.email : ""}
        error={touched.email && Boolean(errors.email)}
        className={classes.root}
        margin="dense"
        fullWidth
      />
      <ButtonSubmit type="submit" title="QUOTE REQUEST" />
    </form>
  )
}

const Form = withFormik({
  mapPropsToValues: ({ name, email, pathname }) => {
    return {
      name: name || "",
      email: email || "",
      pathname: pathname || "",
    }
  },

  validationSchema: yup.object().shape(validationsForm),

  handleSubmit: (values, actions) => {
    const firstName = values.name.split(" ").slice(0, -1).join(" ")
    const lastName = values.name.split(" ").slice(-1).join(" ")

    const encode = data => {
      return Object.keys(data)
        .map(
          key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key])
        )
        .join("&")
    }
    fetch("/", {
      method: "POST",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
      body: encode({ "form-name": "consultation", ...values }),
    })
      .then(() => {
        fetch(
          "https://api.pipedrive.com/v1/organizations?api_token=c0f3d6c6429acffd11ac70ceb6f05f079ba3ef69",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              name: "Consultation",
            }),
          }
        )
          .then(res => res.json())
          .then(data => {
            fetch(
              "https://api.pipedrive.com/v1/persons?api_token=c0f3d6c6429acffd11ac70ceb6f05f079ba3ef69",
              {
                method: "POST",
                headers: {
                  "Content-Type": "application/json",
                },
                body: JSON.stringify({
                  name: `${values.name}`,
                  first_name: firstName,
                  last_name: lastName,
                  email: values.email,
                  org_id: data.data.id,
                  fc1a3d516be8443770289cd03b14b63560bb320b: "Consultation",
                  fa802f3ad564831ce9bdb13c3ae23ab95a6b2b77: `https://alpacked.com${values.pathname}`,
                }),
              }
            )
              .then(res => res.json())
              .then(data => {
                fetch(
                  "https://api.pipedrive.com/v1/leads?api_token=c0f3d6c6429acffd11ac70ceb6f05f079ba3ef69",
                  {
                    method: "POST",
                    headers: {
                      "Content-Type": "application/json",
                    },
                    body: JSON.stringify({
                      title: `${values.name} Lead`,
                      person_id: data.data.id,
                      organization_id: data.data.org_id.value,
                      label_ids: ["689ba920-b01f-11ed-9afd-d57594c8b9af"],
                    }),
                  }
                ).then(res => {
                  if (res.status === 201) {
                    navigate("/thank_you", {
                      state: {
                        name: values.name,
                      },
                    })
                    actions.resetForm()
                  } else {
                    alert(
                      "There was an error submitting the form. Please try again."
                    )
                  }
                })
              })
          })
          .catch(error => {
            alert("There was an error submitting the form. Please try again.")
          })
          .finally(() => actions.setSubmitting(false))
      })
      .catch(() => {
        alert("Error")
      })
      .finally(() => actions.setSubmitting(false))
  },
})(form)

export default withStyles(styles)(Form)
