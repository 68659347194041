import PropTypes from "prop-types"
import React from "react"

import Box from "../../components/Box"
import Container from "../../components/UI/Container"
import Form from "./form"
import Heading from "../../components/Heading"
import Text from "../../components/Text"
import ConsultationWrapper, { ContactFormWrapper } from "./consultation.style"

const Consultation = ({
  pathname,
  textArea,
  title,
  description,
  colorReverse,
  extraPadding,
  greyBg,
}) => {
  return (
    <ConsultationWrapper
      greyBg={greyBg}
      colorReverse={colorReverse}
      extraPadding={extraPadding}
    >
      <Container width="1160px">
        <Box {...textArea}>
          <Heading
            as="h2"
            content="Let's arrange a free consultation"
            {...title}
          />
          <Text
            content="Just fill the form below and we will contaсt you via email to arrange a free call to discuss your project and estimates."
            {...description}
          />
        </Box>
        <ContactFormWrapper>
          <Form pathname={pathname} />
        </ContactFormWrapper>
      </Container>
    </ConsultationWrapper>
  )
}

Consultation.propTypes = {
  textArea: PropTypes.object,
  title: PropTypes.object,
  description: PropTypes.object,
  colorReverse: PropTypes.bool,
  greyBg: PropTypes.bool,
  extraPadding: PropTypes.bool,
}

Consultation.defaultProps = {
  textArea: {
    mb: ["60px", "70px", "70px", "0", "0"],
    pr: ["0", "0", "0", "60px", "75px"],
    width: ["100%", "100%", "100%", "54%"],
  },
  title: {
    fontSize: ["26px", "32px", "40px", "40px"],
    fontWeight: "700",
    color: "#fff",
    lineHeight: ["36px", "50px"],
    mb: ["32px"],
    textAlign: ["center", "center", "center", "left", "left"],
  },
  description: {
    fontSize: ["16px", "20px", "22px", "22px"],
    fontWeight: "300",
    color: "#fff",
    lineHeight: ["28px", "36px"],
    mb: 0,
    textAlign: ["center", "center", "center", "left", "left"],
  },
  buttonStyle: {
    type: "button",
    fontSize: "14px",
    fontWeight: "700",
    pl: "30px",
    pr: "30px",
  },
}

export default Consultation
